<script>
import NavigationMixin from './NavigationMixin'

export default {
  name: 'CheckboxLink',
  mixins: [NavigationMixin],
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true
    },
    // eslint-disable-next-line vue/require-prop-types
    to: {
      required: true
    },
    nativeValue: {
      type: String,
      required: true
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    text: {
      type: String,
      default: '',
      required: false
    },
    screenId: {
      type: String,
      default: ''
    }
  },
  computed: {
    checked() {
      return this.value === this.nativeValue
    }
  },
  methods: {
    click(event) {
      // hack to update parent component and store before page redirect
      this.$emit('input', this.nativeValue)
      setTimeout(() => {
        /* TODO: investigate problem with ftp in production
         if (this.isFacebook || this.isInstagram) {
          this.navigateTo()
        } else {
          event.target.dispatchEvent(new MouseEvent('_click', event))
        }*/
        event.target.dispatchEvent(new MouseEvent('_click', event))
      }, 100)
    }
  }
}
</script>

<template>
  <div class="field mb-2">
    <nuxt-link
      v-button-gtm="{ title: title, screen: screenId }"
      :to="to"
      class="custom-checkbox is-unselectable has-text-weight-medium px-3 py-3 is-flex is-align-items-center"
      :class="checked ? 'is-active' : null"
      event="_click"
      @click.native.stop.prevent="click"
    >
      <div class="custom-checkbox__content is-flex-grow-1 pr-1">
        <slot>
          <div
            class="is-size-5 has-text-weight-medium is-inverted"
            :class="checked ? 'has-text-white' : 'has-text-black'"
          >
            <span v-if="icon" class="icon">{{ icon }}</span> {{ title }}
          </div>
          <div
            v-if="text"
            class="is-size-6 has-text-weight-normal mt-1"
            :class="checked ? 'has-text-white' : 'has-text-grey'"
          >
            {{ text }}
          </div>
        </slot>
      </div>
      <i
        v-if="!noIcon"
        class="fa custom-checkbox__icon is-flex-grow-0"
        :class="{
          'fa-custom-check': checked,
          'fa-custom-plus': !checked,
          'is-align-self-flex-center': text,
          'is-align-self-center': !text
        }"
      />
    </nuxt-link>
  </div>
</template>

<style lang="scss" scoped>
.custom-checkbox {
  min-height: 4rem;
  height: auto;
  border-radius: 0.75rem;
  position: relative;
  cursor: pointer;
  background-color: $light;

  &.is-active {
    background-color: $carb;
  }

  &:hover {
    background-color: bulmaDarken($light, 2.5%);
    &.is-active {
      background-color: bulmaDarken($carb, 2.5%);
    }
  }

  &__icon {
    width: 0.75rem;
    height: 0.75rem;
    min-width: 0.75rem;
    min-height: 0.75rem;
    margin: 2px;
  }
}
</style>
