var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing fullheight"},[_c('div',{staticClass:"section has-text-white px-5 mx-1 pt-5 has-text-black"},[_c('span',{staticClass:"logo has-text-centered"},[_c('Logo',{attrs:{"alt":"logo","width":"58","height":"28"}})],1),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"section has-text-white px-5 mx-1 has-text-black is-flex-grow-1"},[_c('div',{staticClass:"gender-select-text has-text-black has-text-weight-bold mt-1 is-size-18px"},[_vm._v("\n      Select your gender:\n    ")]),_vm._v(" "),_c('div',{staticClass:"options"},[_c('nuxt-link',{directives:[{name:"button-gtm",rawName:"v-button-gtm",value:({
          title: 'female',
          screen: _vm.$options.category
        }),expression:"{\n          title: 'female',\n          screen: $options.category\n        }"}],staticClass:"option",attrs:{"to":_vm.nextUrl,"event":"_click"},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.click('female', $event)}}},[_c('img',{attrs:{"src":require("assets/images/onboarding/v2/female.png"),"width":"150px","alt":"Female"}})]),_vm._v(" "),_c('nuxt-link',{directives:[{name:"button-gtm",rawName:"v-button-gtm",value:({
          title: 'male',
          screen: _vm.$options.category
        }),expression:"{\n          title: 'male',\n          screen: $options.category\n        }"}],staticClass:"option",attrs:{"to":_vm.nextUrl,"event":"_click"},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.click('male', $event)}}},[_c('img',{attrs:{"src":require("assets/images/onboarding/v2/male.png"),"width":"150px","alt":"Male"}})])],1)]),_vm._v(" "),_c('div',{staticClass:"section has-text-centered"},[_c('div',{staticClass:"avards has-text-centered my-4 is-flex-grow-0"},[_c('div',{staticClass:"toc-links has-text-centered is-size-7 has-text-weight-medium is-flex is-justify-content-center"},[_c('a',{staticClass:"is-flex-grow-0 mx-3 has-text-grey",attrs:{"href":_vm.$config.legal.privacy_policy,"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('popups/onPrivacyPolicy', true)}}},[_vm._v("\n          Privacy Policy\n        ")]),_vm._v(" "),_c('a',{staticClass:"is-flex-grow-0 mx-3 has-text-grey",attrs:{"href":_vm.$config.legal.terms_and_conditions,"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('popups/onTermsConditions', true)}}},[_vm._v("\n          Terms & Conditions\n        ")]),_vm._v(" "),_c('a',{staticClass:"is-flex-grow-0 mx-3 has-text-grey",attrs:{"href":`mailto:${_vm.$config.legal.email}`,"target":"_blank","rel":"nofollow noopener"}},[_vm._v("\n          Contact Us\n        ")])])])]),_vm._v(" "),_c('PopupIsNotIos')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-size-2 pt-5 pb-2",staticStyle:{"font-weight":"800"}},[_vm._v("\n      Get\n      "),_c('span',{staticClass:"has-text-carb"},[_vm._v("Your"),_c('br'),_vm._v("\n        Personalized")]),_c('br'),_vm._v("\n      Keto Diet Plan\n    ")])
}]

export { render, staticRenderFns }