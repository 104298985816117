var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing fullheight"},[_c('div',{staticClass:"section section--header has-text-white px-5 pt-6 has-text-black has-text-centered"},[_c('span',{staticClass:"logo has-text-centered"},[_c('Logo',{attrs:{"alt":"logo","width":"58","height":"28"}})],1)]),_vm._v(" "),_c('div',{staticClass:"section has-text-white px-5 pt-5 mx-1 has-text-black is-flex-grow-1 has-text-centered"},[_c('div',{staticClass:"has-text-weight-bold is-size-4 pt-4"},[_vm._v("\n      Start losing weight with the power of Keto Diet\n    ")]),_vm._v(" "),_c('div',{staticClass:"gender-select-text has-text-black mt-1 mb-1 is-size-16px has-text-weight-medium has-text-grey"},[_vm._v("\n      Select the Keto Diet type\n    ")]),_vm._v(" "),_c('div',{staticClass:"options is-flex-wrap-wrap"},[_c('nuxt-link',{directives:[{name:"button-gtm",rawName:"v-button-gtm",value:({
          title: 'female',
          screen: _vm.$options.category
        }),expression:"{\n          title: 'female',\n          screen: $options.category\n        }"}],staticClass:"button is-carb is-rounded is-fullwidth mb-3 mx-4 is-uppercase",attrs:{"to":_vm.nextUrl,"event":"_click"},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.click('female', $event)}}},[_vm._v("\n        Keto for women\n      ")]),_vm._v(" "),_c('nuxt-link',{directives:[{name:"button-gtm",rawName:"v-button-gtm",value:({
          title: 'male',
          screen: _vm.$options.category
        }),expression:"{\n          title: 'male',\n          screen: $options.category\n        }"}],staticClass:"button is-black is-rounded is-fullwidth mx-4 is-uppercase",attrs:{"to":_vm.nextUrl,"event":"_click"},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.click('male', $event)}}},[_vm._v("\n        Keto for men\n      ")])],1)]),_vm._v(" "),_c('div',{staticClass:"section has-text-centered"},[_c('div',{staticClass:"avards has-text-centered my-4 is-flex-grow-0"},[_c('div',{staticClass:"toc-links has-text-centered is-size-7 has-text-weight-medium is-flex is-justify-content-center"},[_c('a',{staticClass:"is-flex-grow-0 mx-3 has-text-grey",attrs:{"href":_vm.$config.legal.privacy_policy,"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('popups/onPrivacyPolicy', true)}}},[_vm._v("\n          Privacy Policy\n        ")]),_vm._v(" "),_c('a',{staticClass:"is-flex-grow-0 mx-3 has-text-grey",attrs:{"href":_vm.$config.legal.terms_and_conditions,"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('popups/onTermsConditions', true)}}},[_vm._v("\n          Terms & Conditions\n        ")]),_vm._v(" "),_c('a',{staticClass:"is-flex-grow-0 mx-3 has-text-grey",attrs:{"href":`mailto:${_vm.$config.legal.email}`,"target":"_blank","rel":"nofollow noopener"}},[_vm._v("\n          Contact Us\n        ")])])])]),_vm._v(" "),_c('PopupIsNotIos')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }