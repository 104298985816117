import { mapGetters } from 'vuex'
import { buildQueryParamsFromObject } from '../helpers/buildQueryParamsFromObject'

export default {
  props: {
    to: {
      type: String,
      required: true
    },
    beforeNavigate: {
      type: Function,
      default: () => () => {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters('browser', ['isFacebook', 'isInstagram']),
    ...mapGetters('onboarding', ['version']),
    ftpUrl() {
      if (!process.browser) {
        return ''
      }

      const url = `${window.location.origin}${this.to}`
      const queryParams = {}
      this.addFbclidQuery(queryParams)
      this.addGclidQuery(queryParams)
      this.addMealplanVersionQuery(queryParams)

      const { deviceId } = window.amplitude.getInstance().options

      if (deviceId) {
        queryParams.deviceid = deviceId
      }

      const query = buildQueryParamsFromObject(queryParams)
      const queryString = query ? `?${query}` : ''

      return url + queryString
    },
    isExistFtpQuery() {
      return Boolean(new URL(this.ftpUrl).search)
    }
  },
  methods: {
    navigateTo() {
      const query = this.beforeNavigate()
      const newQuery = query ? this.combineQuery(query) : ''
      window.location.href = `${this.$config.ftp_url}?url=${encodeURIComponent(
        this.ftpUrl + newQuery
      )}`
    },
    combineQuery(query) {
      return this.isExistFtpQuery ? `&${query}` : `?${query}`
    },
    addGclidQuery(queryParams) {
      this.addQuery('gclid', 'gclid', queryParams)
    },
    addFbclidQuery(queryParams) {
      this.addQuery('_fbc', 'fbclid', queryParams)
    },
    addMealplanVersionQuery(queryParams) {
      const version = this.version ?? this.$cookies.get('onboradingVersion')

      if (version) {
        queryParams.mealplanversion = version
      }
    },
    addQuery(cookieName, queryName, queryObject) {
      const cookie = this.$cookies.get(cookieName)
      const query = this.$route.query[queryName]

      if (cookie) {
        queryObject[queryName] = cookie
      } else if (query) {
        queryObject[queryName] = query
      }
    }
  }
}
