<script>
import { mapGetters, mapMutations } from 'vuex'
import Logo from 'assets/images/app_logo_black.svg?inline'
import AppStoreLinkMixin from './AppStoreLinkMixin'
import PopupIsNotIos from '@/components/landing/PopupIsNotIos'

export default {
  name: 'GenderBlock',
  components: {
    Logo,
    PopupIsNotIos
  },
  category: 'gender',
  mixins: [AppStoreLinkMixin],
  props: {
    host: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('onboarding', ['screens', 'nextUrl']),
    ...mapGetters('browser', ['isMobileSafari']),
    appStoreLink() {
      return this.isMobileSafari
        ? 'https://ketoweb.onelink.me/o2QW/2cc1cfba'
        : 'https://ketoweb.onelink.me/o2QW/246bd53'
    }
  },
  mounted() {
    this.gtmPush({
      event: 'autoevent',
      label: 'onboarding',
      category: 'gender_2',
      action: 'screen_view',
      noninteraction: false
    })
  },
  methods: {
    ...mapMutations('user', ['updateOnboarding']),
    updateForm(gender) {
      this.updateOnboarding({ form: { gender } })
      this.gtmPush({
        event: 'autoevent',
        label: 'profile_update',
        category: this.$options.category,
        action: gender,
        noninteraction: false
      })
    },
    click(gender, event) {
      // hack to update parent component and store before page redirect
      this.updateForm(gender)
      this.$emit('input', gender)

      setTimeout(
        () => event.target.dispatchEvent(new MouseEvent('_click', event)),
        100
      )
    }
  }
}
</script>

<template>
  <div class="landing fullheight">
    <div class="section has-text-white px-5 mx-1 pt-6 has-text-black">
      <span class="logo has-text-centered">
        <Logo alt="logo" width="58" height="28" />
      </span>
      <div class="is-size-2 pt-5 mt-2 pb-2" style="font-weight: 800">
        Get
        <span class="has-text-carb"
          >Your<br />
          Personalized</span
        ><br />
        Keto Diet Plan
      </div>
    </div>

    <div class="section has-text-white px-5 mx-1 has-text-black is-flex-grow-1">
      <div
        class="gender-select-text has-text-black has-text-weight-bold mt-2 mb-1 is-size-18px"
      >
        Select your gender:
      </div>

      <div class="options">
        <nuxt-link
          v-button-gtm="{
            title: 'female',
            screen: $options.category
          }"
          class="option"
          :to="nextUrl"
          event="_click"
          @click.native.stop.prevent="click('female', $event)"
        >
          <img
            src="~/assets/images/onboarding/v2/female_v2.png"
            width="150px"
            alt="Female"
          />
        </nuxt-link>
        <nuxt-link
          v-button-gtm="{
            title: 'male',
            screen: $options.category
          }"
          class="option"
          :to="nextUrl"
          event="_click"
          @click.native.stop.prevent="click('male', $event)"
        >
          <img
            src="~/assets/images/onboarding/v2/male_v2.png"
            width="150px"
            alt="Male"
          />
        </nuxt-link>
      </div>
    </div>

    <div class="section has-text-centered">
      <div class="avards has-text-centered my-4 is-flex-grow-0">
        <div
          class="toc-links has-text-centered is-size-7 has-text-weight-medium is-flex is-justify-content-center"
        >
          <a
            class="is-flex-grow-0 mx-3 has-text-grey"
            :href="$config.legal.privacy_policy"
            target="_blank"
            rel="nofollow noopener"
            @click.prevent="$store.dispatch('popups/onPrivacyPolicy', true)"
          >
            Privacy Policy
          </a>
          <a
            class="is-flex-grow-0 mx-3 has-text-grey"
            :href="$config.legal.terms_and_conditions"
            target="_blank"
            rel="nofollow noopener"
            @click.prevent="$store.dispatch('popups/onTermsConditions', true)"
          >
            Terms & Conditions
          </a>
          <a
            class="is-flex-grow-0 mx-3 has-text-grey"
            :href="`mailto:${$config.legal.email}`"
            target="_blank"
            rel="nofollow noopener"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
    <PopupIsNotIos />
  </div>
</template>

<style lang="scss" scoped>
.landing {
  line-height: 1.1;
  display: flex;
  flex-direction: column;

  .section:last-child {
    margin-top: auto;
  }

  .is-text-size-1 {
    font-size: 2.125rem;
    @media screen and (max-width: 380px) {
      font-size: 1.75rem;
    }
    line-height: 1.2;
  }

  .is-text-size-3 {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .fullheight {
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }

  .section {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    @media screen and (min-width: 380px) {
      margin: 0 auto !important;
    }

    &.welcome {
      max-width: 100%;
    }
  }

  .welcome {
    padding-top: 1.5rem;
    padding-bottom: 3.875rem; // curve
    background: #f8f6f4;
    background-size: cover, cover;

    .logo {
      margin: 0 auto 1.25rem;
      width: 100%;
      display: block;
    }

    .main-title {
      margin-bottom: 1.125rem;
    }

    position: relative;

    .curve {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      line-height: 0;

      svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 2rem;
      }

      .shape-fill {
        fill: #ffffff;
      }
    }
  }

  .gender-select-text {
    padding-top: 1.75rem;
    padding-bottom: 1.25rem;
  }

  .options {
    display: flex;
    margin-bottom: 2.375rem;

    .option {
      margin-right: 1.25rem;
      width: 50%;

      &:last-child {
        margin-right: 0;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .toc-links {
    margin-top: 2.75rem;
  }
}
</style>
