import Logo from 'assets/images/logo.svg?inline'
import CheckboxLink from '@/components/CheckboxLink'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AppStoreLinkMixin from './AppStoreLinkMixin'

export default {
  components: {
    Logo,
    CheckboxLink
  },
  mixins: [AppStoreLinkMixin],
  computed: {
    ...mapGetters('onboarding', ['config', 'nextUrl']),
    ...mapGetters('user', ['form']),
    ...mapGetters('browser', ['isMobileSafari']),
    screen() {
      return this.config.landing
    }
  },
  methods: {
    ...mapMutations('user', ['updateOnboarding']),
    ...mapActions('onboarding', ['next']),
    updateForm(key, value) {
      this.updateOnboarding({ form: { [key]: value } })
      this.gtmPush({
        event: 'autoevent',
        label: 'profile_update',
        category: this.$options.category,
        action: value,
        noninteraction: false
      })
    }
  }
}
