export default {
  methods: {
    appstoreClick() {
      this.gtmPush({
        event: 'autoevent',
        label: 'onboarding',
        category: 'download1',
        action: 'appstore_click',
        noninteraction: false
      })
    }
  }
}
