<script>
import { mapGetters, mapMutations } from 'vuex'
import Logo from 'assets/images/logo.svg?inline'
import AppStoreLinkMixin from './AppStoreLinkMixin'

export default {
  name: 'Gender',
  components: {
    Logo
  },
  category: 'gender',
  mixins: [AppStoreLinkMixin],
  props: {
    host: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('onboarding', ['screens', 'nextUrl']),
    ...mapGetters('browser', ['isMobileSafari']),
    appStoreLink() {
      return this.isMobileSafari
        ? 'https://ketoweb.onelink.me/o2QW/2cc1cfba'
        : 'https://ketoweb.onelink.me/o2QW/246bd53'
    }
  },
  mounted() {
    this.gtmPush({
      event: 'autoevent',
      label: 'onboarding',
      category: 'gender_2',
      action: 'screen_view',
      noninteraction: false
    })
  },
  methods: {
    ...mapMutations('user', ['updateOnboarding']),
    updateForm(gender) {
      this.updateOnboarding({ form: { gender } })
      this.gtmPush({
        event: 'autoevent',
        label: 'profile_update',
        category: this.$options.category,
        action: gender,
        noninteraction: false
      })
    },
    click(gender, event) {
      // hack to update parent component and store before page redirect
      this.$emit('input', this.nativeValue)
      setTimeout(
        () => event.target.dispatchEvent(new MouseEvent('_click', event)),
        100
      )
    }
  }
}
</script>

<template>
  <div class="landing fullheight">
    <center class="welcome section has-text-white px-5 has-text-black">
      <a
        class="logo has-text-centered"
        :href="appStoreLink"
        target="_blank"
        @click="appstoreClick"
      >
        <Logo alt="logo" width="48" height="48" />
      </a>
      <div class="main-title is-text-size-1 has-text-weight-bold">
        Start losing weight
      </div>
      <div class="is-text-size-3 has-text-weight-semibold">
        According to your Age & BMI
      </div>
      <div class="curve">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          fill="#fff"
        >
          <path
            d="M600 112.8C268.6 112.8 70 40 0 7.2V120h1200V7.2c-70 40-268.6 105.6-600 105.6z"
          />
        </svg>
      </div>
    </center>

    <center class="section has-text-white px-5 has-text-black">
      <div class="gender-select-text has-text-black has-text-weight-bold mt-1">
        Select your gender:
      </div>

      <div class="options">
        <nuxt-link
          v-button-gtm="{
            title: 'male',
            screen: $options.category
          }"
          class="option"
          :to="nextUrl"
          event="_click"
          @click.native.stop.prevent="click('male', $event)"
        >
          <img src="/images/onboarding/v1.2/male.png" alt="Male" />
        </nuxt-link>
        <nuxt-link
          v-button-gtm="{
            title: 'female',
            screen: $options.category
          }"
          class="option"
          :to="nextUrl"
          event="_click"
          @click.native.stop.prevent="click('female', $event)"
        >
          <img src="/images/onboarding/v1.2/female.png" alt="Female" />
        </nuxt-link>
      </div>
    </center>

    <center class="section">
      <div class="avards has-text-centered my-4 is-flex-grow-0">
        <svg
          class="mx-1"
          width="106"
          height="50"
          fill="#666"
          xmlns="http://www.w3.org/2000/svg"
        >
          <text
            text-anchor="middle"
            x="52"
            y="27.2"
            font-size="34"
            font-weight="bold"
            letter-spacing="-2"
          >
            1.5 M
          </text>
          <text
            text-anchor="middle"
            x="52"
            y="46.9"
            font-size="12"
            font-weight="500"
          >
            Downloads
          </text>
          <path d="M6.3 6c1.9 0 4.1-3.4 4.8-5.3 0 0-3.7.4-4.8 5.2z" />
          <path
            d="M5.5 8.1a5.8 5.8 0 004.3-2.6s-2-.5-4 1.4c.6-1.5.8-3.4.1-4.6C6 2.3 3.2 5 5 7.9c-.2.2-.4.5-.4 1A.3.3 0 105 9c0-.3.1-.5.3-.6l.1-.2z"
          />
          <path
            d="M2.8 12.8a4.2 4.2 0 010-.1c-2.6-4 .2-6 .2-6 .6 1.2.9 3.2.6 4.8C4.8 8.9 7.3 9 7.3 9a6.9 6.9 0 01-3.9 4.2l.2.4a.3.3 0 01-.5.2 4 4 0 01-.3-1z"
          />
          <path
            d="M3.7 17.8c0-.1 0-.2.2-.3 1.4-1 2.7-3.2 2.7-4.9 0 0-2.4.5-3 3l-2-3.7L1 11S-.2 15.5 3.2 18l.3.8a.3.3 0 10.5-.3 7 7 0 01-.3-.6z"
          />
          <path
            d="M5.2 21.7c1.2-1 1.1-3.5 1.1-5.3V16s-3 1.4-2 4.6c-1-1.6-3-3.3-4.2-4.2s-.6 5.6 4.7 5.9l.3.4a.3.3 0 10.4-.3 3.3 3.3 0 01-.3-.7z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.2 23.4c1.2 1.4 2.7 3.2 2.8 4.7l.7.2a.3.3 0 010 .5c-.4 0-.8-.2-1.1-.4a3.7 3.7 0 01-.2 0C8.1 31 5.2 27 5.2 27c.7-.3 4.8 0 6.8.8-3.3-1.6-2.7-5.6-2.7-5.6l.9 1zm2 4.6z"
          />
          <path
            d="M7.5 25.2c1-1.6 0-4-.8-5.8v-.1s-2.1 2.3.3 5.4c-1-1-3.3-2-5-2.8l-1.2-.5s1.4 5.2 6.4 4.2l.5.3a.3.3 0 10.3-.4 1.7 1.7 0 01-.5-.3zM99.7 5.9c-1.9.1-4.1-3.3-4.8-5.2 0 0 3.7.4 4.8 5.2z"
          />
          <path
            d="M100.5 8.1a5.8 5.8 0 01-4.3-2.6s2-.5 4 1.4c-.6-1.5-.8-3.4-.1-4.6 0 0 2.7 2.7.9 5.6.2.2.4.5.4 1a.3.3 0 01-.5 0 1 1 0 00-.3-.6 1.4 1.4 0 00-.1-.2z"
          />
          <path
            d="M103.2 12.8v-.1c2.6-4-.2-6-.2-6-.6 1.2-.9 3.2-.6 4.8-1.2-2.6-3.7-2.5-3.7-2.5a6.9 6.9 0 003.9 4.2 3 3 0 01-.2.4.3.3 0 10.5.2l.3-1z"
          />
          <path
            d="M102.3 17.8c0-.1 0-.2-.2-.3a6.9 6.9 0 01-2.7-4.9s2.4.5 3 3l2-3.7.5-.8s1.3 4.4-2.1 6.8a3.7 3.7 0 01-.3.8.3.3 0 11-.5-.3l.3-.6z"
          />
          <path
            d="M100.8 21.7c-1.2-1-1.1-3.5-1.1-5.3V16s3 1.4 2 4.6c1-1.6 3-3.3 4.2-4.2s.6 5.6-4.7 5.9l-.3.4a.3.3 0 11-.4-.3 3.3 3.3 0 00.3-.7z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M95.8 23.4c-1.2 1.4-2.7 3.2-2.8 4.7l-.7.2a.3.3 0 000 .5 2.2 2.2 0 001.3-.5c4.3 2.8 7.2-1.2 7.2-1.2-.7-.3-4.8 0-6.8.8 3.3-1.6 2.7-5.6 2.7-5.6l-.9 1zm-2 4.6z"
          />
          <path
            d="M98.5 25.2c-1-1.6 0-4 .8-5.8v-.1s2.1 2.3-.3 5.4c1-1 3.3-2 5-2.8l1.2-.5s-1.4 5.2-6.4 4.2a2.5 2.5 0 01-.5.3.3.3 0 11-.3-.4l.5-.3z"
          />
        </svg>

        <svg
          class="mx-1"
          width="106"
          height="50"
          fill="#666"
          xmlns="http://www.w3.org/2000/svg"
        >
          <text
            text-anchor="middle"
            x="54"
            y="27.2"
            font-size="34"
            font-weight="bold"
          >
            #1
          </text>
          <text
            text-anchor="middle"
            x="54"
            y="46.9"
            font-size="12"
            font-weight="500"
          >
            Keto Diet App
          </text>
          <path d="M6.3 6c1.9 0 4.1-3.4 4.8-5.3 0 0-3.7.4-4.8 5.2z" />
          <path
            d="M5.5 8.1a5.8 5.8 0 004.3-2.6s-2-.5-4 1.4c.6-1.5.8-3.4.1-4.6C6 2.3 3.2 5 5 7.9c-.2.2-.4.5-.4 1A.3.3 0 105 9c0-.3.1-.5.3-.6l.1-.2z"
          />
          <path
            d="M2.8 12.8a4.2 4.2 0 010-.1c-2.6-4 .2-6 .2-6 .6 1.2.9 3.2.6 4.8C4.8 8.9 7.3 9 7.3 9a6.9 6.9 0 01-3.9 4.2l.2.4a.3.3 0 01-.5.2 4 4 0 01-.3-1z"
          />
          <path
            d="M3.7 17.8c0-.1 0-.2.2-.3 1.4-1 2.7-3.2 2.7-4.9 0 0-2.4.5-3 3l-2-3.7L1 11S-.2 15.5 3.2 18l.3.8a.3.3 0 10.5-.3 7 7 0 01-.3-.6z"
          />
          <path
            d="M5.2 21.7c1.2-1 1.1-3.5 1.1-5.3V16s-3 1.4-2 4.6c-1-1.6-3-3.3-4.2-4.2s-.6 5.6 4.7 5.9l.3.4a.3.3 0 10.4-.3 3.3 3.3 0 01-.3-.7z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.2 23.4c1.2 1.4 2.7 3.2 2.8 4.7l.7.2a.3.3 0 010 .5c-.4 0-.8-.2-1.1-.4a3.7 3.7 0 01-.2 0C8.1 31 5.2 27 5.2 27c.7-.3 4.8 0 6.8.8-3.3-1.6-2.7-5.6-2.7-5.6l.9 1zm2 4.6z"
          />
          <path
            d="M7.5 25.2c1-1.6 0-4-.8-5.8v-.1s-2.1 2.3.3 5.4c-1-1-3.3-2-5-2.8l-1.2-.5s1.4 5.2 6.4 4.2l.5.3a.3.3 0 10.3-.4 1.7 1.7 0 01-.5-.3zM99.7 5.9c-1.9.1-4.1-3.3-4.8-5.2 0 0 3.7.4 4.8 5.2z"
          />
          <path
            d="M100.5 8.1a5.8 5.8 0 01-4.3-2.6s2-.5 4 1.4c-.6-1.5-.8-3.4-.1-4.6 0 0 2.7 2.7.9 5.6.2.2.4.5.4 1a.3.3 0 01-.5 0 1 1 0 00-.3-.6 1.4 1.4 0 00-.1-.2z"
          />
          <path
            d="M103.2 12.8v-.1c2.6-4-.2-6-.2-6-.6 1.2-.9 3.2-.6 4.8-1.2-2.6-3.7-2.5-3.7-2.5a6.9 6.9 0 003.9 4.2 3 3 0 01-.2.4.3.3 0 10.5.2l.3-1z"
          />
          <path
            d="M102.3 17.8c0-.1 0-.2-.2-.3a6.9 6.9 0 01-2.7-4.9s2.4.5 3 3l2-3.7.5-.8s1.3 4.4-2.1 6.8a3.7 3.7 0 01-.3.8.3.3 0 11-.5-.3l.3-.6z"
          />
          <path
            d="M100.8 21.7c-1.2-1-1.1-3.5-1.1-5.3V16s3 1.4 2 4.6c1-1.6 3-3.3 4.2-4.2s.6 5.6-4.7 5.9l-.3.4a.3.3 0 11-.4-.3 3.3 3.3 0 00.3-.7z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M95.8 23.4c-1.2 1.4-2.7 3.2-2.8 4.7l-.7.2a.3.3 0 000 .5 2.2 2.2 0 001.3-.5c4.3 2.8 7.2-1.2 7.2-1.2-.7-.3-4.8 0-6.8.8 3.3-1.6 2.7-5.6 2.7-5.6l-.9 1zm-2 4.6z"
          />
          <path
            d="M98.5 25.2c-1-1.6 0-4 .8-5.8v-.1s2.1 2.3-.3 5.4c1-1 3.3-2 5-2.8l1.2-.5s-1.4 5.2-6.4 4.2a2.5 2.5 0 01-.5.3.3.3 0 11-.3-.4l.5-.3z"
          />
        </svg>

        <div
          class="toc-links has-text-centered is-size-7 has-text-weight-medium is-flex is-justify-content-center"
        >
          <a
            class="is-flex-grow-0 mx-3 has-text-grey"
            :href="$config.legal.privacy_policy"
            target="_blank"
            rel="nofollow noopener"
            @click.prevent="$store.dispatch('popups/onPrivacyPolicy', true)"
          >
            Privacy Policy
          </a>
          <a
            class="is-flex-grow-0 mx-3 has-text-grey"
            :href="$config.legal.terms_and_conditions"
            target="_blank"
            rel="nofollow noopener"
            @click.prevent="$store.dispatch('popups/onTermsConditions', true)"
          >
            Terms & Conditions
          </a>
        </div>
        <div
          class="mt-3 is-size-7 has-text-weight-medium is-flex is-justify-content-center has-text-grey"
        >
          <span>2021 © All Rights Reserved.</span>
        </div>
      </div>
    </center>
  </div>
</template>

<style lang="scss" scoped>
.landing {
  line-height: 1.375;
  display: flex;
  flex-direction: column;

  .section:last-child {
    margin-top: auto;
  }

  .is-text-size-1 {
    font-size: 2.125rem;
    @media screen and (max-width: 380px) {
      font-size: 1.75rem;
    }
    line-height: 1.2;
  }

  .is-text-size-3 {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .fullheight {
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }

  .section {
    margin: 0 auto;
    width: 100%;
    max-width: 700px;

    &.welcome {
      max-width: 100%;
    }
  }

  .welcome {
    padding-top: 1.5rem;
    padding-bottom: 3.875rem; // curve
    background: #f8f6f4;
    background-size: cover, cover;

    .logo {
      margin: 0 auto 1.25rem;
      width: 100%;
      display: block;
    }

    .main-title {
      margin-bottom: 1.125rem;
    }

    position: relative;

    .curve {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      line-height: 0;

      svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 2rem;
      }

      .shape-fill {
        fill: #ffffff;
      }
    }
  }

  .gender-select-text {
    padding-top: 1.75rem;
    padding-bottom: 1.25rem;
  }

  .options {
    display: flex;
    margin-bottom: 2.375rem;

    .option {
      margin-right: 1.25rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .toc-links {
    margin-top: 2.75rem;
  }
}
</style>
