<script>
import { mapMutations, mapState } from 'vuex'
import { EXPERIMENTS } from '@/enums'
import LandingV1 from '@/components/landing/KetoGoals'
import LandingV3 from '@/components/landing/Gender'
import LandingV4 from '@/components/landing/GenderV2'
import LandingV5 from '@/components/landing/GenderV3'
import LandingV6 from '@/components/landing/GenderV4'

import HighestInFat from '@/components/landing/HighestInFat'

export default {
  name: 'IndexBlock',
  components: {
    LandingV1,
    LandingV3,
    LandingV4,
    LandingV5,
    LandingV6,
    HighestInFat
  },
  layout: 'landing',
  asyncData({ req }) {
    return {
      host: req ? req.headers['x-forwarded-host'] : window.location.host
    }
  },
  data() {
    return { EXPERIMENTS, loading: true }
  },
  computed: {
    ...mapState('onboarding', ['version'])
  },
  mounted() {
    this.resetOnboardingForm()
  },
  methods: {
    ...mapMutations('user', ['resetOnboardingForm'])
  }
}
</script>

<template>
  <client-only>
    <LandingV3 v-if="version === EXPERIMENTS.ONBOARDING.V1_2" :host="host" />
    <HighestInFat
      v-else-if="version === EXPERIMENTS.ONBOARDING.V1_9_TRIAL_V1_1"
      :host="host"
    />
    <LandingV4
      v-else-if="version === EXPERIMENTS.ONBOARDING.V2_1_INTRODUCTORY_V0_1"
      :host="host"
    />
    <LandingV5
      v-else-if="version === EXPERIMENTS.ONBOARDING.V2_2_INTRODUCTORY_V0_1"
      :host="host"
    />
    <LandingV6
      v-else-if="
        version === EXPERIMENTS.ONBOARDING.V2_3_INTRODUCTORY_V0_1 ||
        version === EXPERIMENTS.ONBOARDING.V2_0_INTRODUCTORY_V0_2
      "
      :host="host"
    />
    <LandingV1 v-else :host="host" />
  </client-only>
</template>
